<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="排口名称">
                <el-input @change="onQuerySubmit" v-model="query.equipment_name" placeholder="请输入排口名称"></el-input>
              </el-form-item>
              <el-form-item label="卡号">
                <el-input @change="onQuerySubmit" v-model="query.number" placeholder="请输入卡号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit">查询</el-button>
                <el-button type="primary" @click="simCreate" v-if="permission.apiSimCardSimAdd">添加物联卡</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <el-col :span="24">
            <el-table :data="tableData.data" stripe style="width: 100%">
              <el-table-column prop="equipment_name" label="排口名称" min-width="150">
              </el-table-column>
              <el-table-column prop="number" label="卡号" min-width="100">
              </el-table-column>
              <el-table-column prop="name" label="物联卡名称" min-width="150">
              </el-table-column>
              <el-table-column prop="carrieroperator" label="运营商" min-width="150">
              </el-table-column>
              <el-table-column prop="meal" label="套餐" min-width="150">
              </el-table-column>
              <el-table-column prop="start_at" label="激活时间" min-width="150">
              </el-table-column>
              <el-table-column prop="notice_at" label="提醒时间" min-width="150">
              </el-table-column>
              <el-table-column prop="expired_at" label="到期时间" min-width="150">
              </el-table-column>
              <el-table-column fixed="right" label="操作" min-width="150">
                <template slot-scope="scope">
                  <el-button @click="simDatail(scope.row)" type="text" size="small">详情</el-button>
                  <el-button type="text" size="small" @click="deleteItem(scope.row)" v-if="permission.apiSimCardDelete">注销</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background :page-size="10" @current-change="changeCurrent" :current-page="query.page" :total="tableData.total"></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiSimCardList, apiSimCardDelete } from "src/api/index";
import md5 from 'js-md5';
export default {
  data() {
    return {
      loading: false,
      query: {
        equipment_name: '',
        number: '',
        page: 1,
        logotype: 'basicSim'
      },
      tableData: {}
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5('query')))
    if (query === null) {
      _this.query = _this.query
    } else if (query.logotype === 'basicSim') {
      _this.query =  query;
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    _this.changeCurrent(_this.query.page);
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    permission: (state) => state.permission,
    url: (state) => state.url,
  }),
  watch: {
    '$route': 'getSimList'
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询物联卡信息
    onQuerySubmit() {
      this.query.page = 1;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&equipment_name=' + this.query.equipment_name + '&number=' + this.query.number;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getSimList();
      } else {       
        this.$router.replace(route);
      }
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&equipment_name=' + this.query.equipment_name + '&number=' + this.query.number;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getSimList();
      } else {       
        this.$router.replace(route);
      }
    },
    //获取物联卡列表数据
    getSimList() {
      const _this = this;
      _this.loading = true;
      apiSimCardList(_this.query)
      .then((res) => {
        _this.loading = false;
        _this.tableData = res;
      })
      .catch((err) => {
        _this.loading = false;
        _this.error(err);
      })
    },
    //跳转物联卡添加页面
    simCreate() {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/basic/sim-add')
    },
    //跳转物联卡详情页面
    simDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/basic/sim-detail/'+ item.id)
    },
    //删除物联卡信息
    deleteItem(item) {
      const _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        apiSimCardDelete({id:item.id})
        .then((res) => {
          _this.$message({
            message: "注销成功",
            type: "success",
          });
          _this.onQuerySubmit();
        })
        .catch((err) => {
          _this.error(err) ;
        })
      })
    },
    //错误信息提示
    error(err) {
      if (err.message === '没有操作权限') {
        return
      } else {         
        this.$message.error(err.message);
      }
    }
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
    position: relative;
    margin-top: 7px;
}
/deep/ .el-table--small td, .el-table--small th {
  padding: 0;
}
/deep/ .el-table td, .el-table th {
  padding: 0;
}
/deep/ .is-leaf {
  padding: 8px 0;
}
</style>